@import 'normalize.css/normalize.css';

/*! main.css */

/* Color palette as CSS */

/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=53u0u0k96JH1FZe51T3dAywinrM */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */
/*
.color-primary-0 { color: #96B9CD } /* Main Primary color
.color-primary-1 { color: #EEF5FA }
.color-primary-2 { color: #C6DBE8 }
.color-primary-3 { color: #6690AA }
.color-primary-4 { color: #41718D }

.color-secondary-1-0 { color: #A1A4D5 } /* Main Secondary color (1)
.color-secondary-1-1 { color: #F0F0FB }
.color-secondary-1-2 { color: #CCCEEC }
.color-secondary-1-3 { color: #7479B7 }
.color-secondary-1-4 { color: #4E5399 }

.color-secondary-2-0 { color: #99D6B7 } /* Main Secondary color (2)
.color-secondary-2-1 { color: #EEFBF4 }
.color-secondary-2-2 { color: #C7ECD9 }
.color-secondary-2-3 { color: #6AB991 }
.color-secondary-2-4 { color: #429B6E }
 */

/* General */

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 16px;
}

.image--responsive {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #96B9CD;
  margin: 0 0 .75em;
}

a:link,
a:visited {
  color: #96B9CD;
  text-decoration: none;
}

a:hover,
a:active {
  color: #6690AA;
  text-decoration: underline;
}

dt {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

dd {
  margin-bottom: 1.5rem;
}

.banner {
  background-color: #A1A4D5;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
}

.banner--highlight {
  background-color: #6AB991;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
}

a:link.banner__link,
a:visited.banner__link {
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
}

/* Header */
.site-header {
  border-bottom: 2px solid #6690AA;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
}

.site-menu {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
}

.site-menu__link-item {
  display: inline-flex;
  padding-right: 2em;
}

.tagline {
  font-size: 1.125rem;
  font-style: italic;
}

.heading--centered {
  margin-top: 2rem;
  text-align: center;
}

/* Navigation */

.page-nav {
  padding: .5rem 1rem;
}

.breadcrumbs {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs__item {
  display: inline-block;
  padding: 1rem 0;
}

.breadcrumbs__item:not(:last-child):after {
  content: '>';
  padding: 0 1rem;
}

/* Main content */
.main-content {
  padding: 1rem;
}


/* Special */
.triptych {
  display: flex;
  flex-direction: column;
}


@media screen and (min-width: 600px) {
  .triptych {
    flex-direction: row;
    justify-content: space-between;
  }

  .triptych__item {
    margin-left: 16px;
    margin-right: 16px;
  }

  .triptych__item:first-child {
    margin-left: 0;
  }

  .triptych__item:last-child {
    margin-right: 0;
  }
}

/* Fountains */

.fountain-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.fountain-list .fountain {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0.5rem;
  max-width: 450px;
}

.fountain-list .fountain__heading {
  text-align: center;
}

.fountain-list .fountain__body {
  margin: 0 auto 1rem;
}

.fountain__description {
  margin-bottom: 1.5rem;
}

.fountain__address {
  background-color: #C6DBE8;
  padding: 1rem;
}

.fountain-detail {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.fountain-detail__heading {
  border-bottom: 1px solid #6690AA;
  padding-bottom: .375em;
  width: 100%;
}

.fountain-detail__address {
  margin-bottom: 1rem;
}

.fountain-detail__map,
.fountain-detail__video {
  height: 0;
  overflow: hidden;
  position: relative;
}

.fountain-detail__map {
  padding-bottom: 100%;
}

.fountain-detail__video {
  padding-bottom: 56.25%;
}

.fountain-detail__map iframe,
.fountain-detail__video iframe {
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}
/*
.fountain-detail__map iframe {
}

.fountain-detail__video iframe {
  height: 56.25% !important;
}
*/
.fountain-detail__info,
.fountain-detail__graphics {
  margin-bottom: 1rem;
}

@media screen and (min-width: 600px) {
  .fountain-detail__info,
  .fountain-detail__graphics {
    flex: 1;
    padding: .25rem;
    width: auto;
  }

  .fountain-detail__graphics {
    padding-left: 3rem;
  }
}

.fountain-detail__images,
.fountain-detail__videos {
  margin-bottom: 1.5rem;
}

/* Footer */
.footer {
  border-top: 2px solid #6690AA;
  font-size: .875rem;
  margin: 1rem 0 0;
  padding: 1rem 1rem;
}

.footer__links {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
}
.footer__link-item {
  display: inline-flex;
  padding-right: 2em;
}
